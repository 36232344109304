body {
  background-color: #181818;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* justify-content: center; */
  font-size: calc(1px + 2vmin);
  color: white;
}

.container {
  padding: 0.2%;
  font-size: 16px;
}

.colourpicker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #cd6c48;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.hr {
  width: 50%;
}